import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionService } from '../../../../../../../service/session/session.service';
import { CaixaPostalService } from '../../../../../../../service/caixa-postal/caixa-postal.service';

import * as FileSaver from 'file-saver';
import moment from 'moment';
import swal from 'sweetalert2';
import { getSwal } from '../../../../../../../utils/swalObject';

@Component({
  selector: 'app-respostas',
  templateUrl: 'respostas.component.html',
  styleUrls: ['./respostas.component.scss']
})
export class RespostasComponent implements OnInit {
  @Input('mensagem') mensagem: any;
  @Output() onReplyMessage: EventEmitter<any> = new EventEmitter<any>();

  reply: boolean = false;
  loading: boolean = false;

  constructor(
    private sessionService: SessionService,
    private caixaPostalService: CaixaPostalService
  ) {}

  ngOnInit() {
    this.verificarLeituraMensagem(this.mensagem);
  }

  obterAnexo(protocolo, anexo) {
    this.caixaPostalService
      .baixarAnexo(protocolo, anexo.codigo)
      .subscribe(
        res => this.baixarAnexo(res, anexo),
        error => console.log(error)
      );
  }

  obterTodosAnexos(protocolo) {
    this.caixaPostalService
      .baixarTodosAnexos(protocolo)
      .subscribe(res => this.baixarTodos(res), error => console.log(error));
  }

  baixarAnexo(data, anexo) {
    var blob = new Blob([data], { type: 'application/pdf' });
    FileSaver.saveAs(blob, `${anexo.nomeAnexo}`);
  }

  gerarProtocolo(mensagem) {
    this.loading = true;
    this.caixaPostalService.gerarProtocolo(mensagem.protocolo).subscribe(
      response => {
        this.loading = false;
        var blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `dossie`);
      },
      error => {
        this.loading = false;
        swal.fire(
          getSwal(
            'success',
            '',
            'Comunicado ' +
              mensagem.assunto +
              ' recebido em ' +
              moment(mensagem.dataEnvio).format('DD/MM/YYYY HH:mm:ss') +
              ' de número ' +
              mensagem.protocolo,
            'ok',
            null
          )
        );
      }
    );
  }

  baixarTodos(data) {
    var blob = new Blob([data], { type: 'application/zip' });
    FileSaver.saveAs(blob, `arquivos.zip`);
  }

  validaUsuarioResponde(mensagem) {
    //funcao que verifica se o remetente é diferente do usuario a responder ou se o remetente é a aplicacao
    //caso algumas das condições seja verdadeira, o usuario poderá responder, caso contrário, não
    // console.log('valida usuario responde : ', mensagem);
    if (!mensagem.tipoComunicado.contribuinteResponde) {
      return false;
    }
    let session: Object = this.sessionService.getSession();
    let remetente = mensagem.remetente;
    if (mensagem.situacaoMensagem.situacao === 2) return false;
    else if (
      remetente.usuario &&
      remetente.usuario.cpfCnpj !== session['usuario'].cpfCnpj
    )
      return true;
    else if (remetente.aplicacao !== null) return false;
    else return false;
  }

  replyMessage() {
    this.reply = true;
    this.onReplyMessage.emit(this.reply);
  }

  verificarLeituraMensagem(mensagem){
    let usuario = JSON.parse(localStorage.getItem('session'));
    let cpfCnpjUsuario = usuario.usuario.cpfCnpj;
    let destinatario;

    if(mensagem.destinatario.usuario !== null) {
      destinatario = mensagem.destinatario.usuario.cpfCnpj;
    }

    if(destinatario == cpfCnpjUsuario && mensagem.leitura.length == 0) {
      this.marcarComoLida(mensagem);
    }
  }

  marcarComoLida(mensagem){
    this.caixaPostalService.mensagemLida(mensagem.protocolo).subscribe(
      res => {
    },
      error => {
      console.log("Erro marcar mensagem como lida", error);
    });
  }
}
