import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { IndexRoutingModule } from './index.routing.module';
import { IndexComponent } from './paginas/index.component';

@NgModule({
  
  imports: [ 
    IndexRoutingModule,
    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [ 
    IndexComponent
  ],
  providers: [],
})
export class IndexModule { }
