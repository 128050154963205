import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AplicacoesService } from '../../../service/aplicacoes/aplicacoes.service';
import { SessionService } from '../../../service/session/session.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../service/user-service/user.service';
import { LoginService } from '../../../service/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';
import {CadastroService} from "../../../service/cadastro/cadastro.service";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  loading: boolean = true;
  aplicacoes: Array<any> = [];
  searchForm: FormGroup;
  searchText: string = '';
  labels: any;
  icones: Array<any> = [
    { name: '#cog', id: 0, selected: false },
    { name: '#megaphone', id: 1, selected: false },
    { name: '#monitor', id: 2, selected: false },
    { name: '#folder', id: 3, selected: false },
    { name: '#inbox', id: 4, selected: false },
    { name: '#world', id: 5, selected: false },
    { name: '#user', id: 6, selected: false },
    { name: '#mail', id: 7, selected: false },
    { name: '#tools', id: 8, selected: false },
    { name: '#file', id: 9, selected: false },
    { name: '#eye', id: 10, selected: false },
    { name: '#firewall', id: 11, selected: false },
    { name: '#flag', id: 12, selected: false },
    { name: '#info', id: 13, selected: false },
    { name: '#judge', id: 14, selected: false },
    { name: '#light-bulb', id: 16, selected: false },
    { name: '#molecule', id: 17, selected: false },
    { name: '#network', id: 18, selected: false },
    { name: '#notepad', id: 19, selected: false },
    { name: '#pie-chart', id: 20, selected: false },
    { name: '#open-book', id: 21, selected: false },
    { name: '#podium', id: 22, selected: false },
    { name: '#view', id: 23, selected: false },
    { name: '#telephone', id: 24, selected: false },
    { name: '#team', id: 25, selected: false },
    { name: '#shake-hands', id: 26, selected: false },
    { name: '#notebook', id: 27, selected: false },
    { name: '#coding', id: 28, selected: false },
    { name: '#coin', id: 29, selected: false },
    { name: '#clock', id: 31, selected: false },
    { name: '#upload', id: 33, selected: false },
    { name: '#thumb-up', id: 34, selected: false },
    { name: '#layers', id: 35, selected: false },
    { name: '#info', id: 36, selected: false },
    { name: '#pencil', id: 37, selected: false },
    { name: '#unlocked', id: 38, selected: false },
    { name: '#pie-chart', id: 39, selected: false },
    { name: '#worldwide', id: 40, selected: false },
    { name: '#people-connection', id: 41, selected: false },
    { name: '#children-charity', id: 42, selected: false },
    { name: '#arroba', id: 43, selected: false }
  ];

  loginStatus: string = '';

  usaCadastro: boolean = true;

  cpf_cnpj = '';
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';
  pureResult: any;
  maskedId: any;
  val: any;
  v: any;
  loginForm: FormGroup;
  errorMsg: string = null;
  hasError = false;
  currentCnpj: string;
  exigeCertificadoCnpj : boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private aplicacoesService: AplicacoesService,
    private userService: UserService,
    private sessionService: SessionService,
    private translate: TranslateService,
    private cadastroService: CadastroService,
  ) {
    this.setLabels();
  }

  ngOnInit() {
    this.criarFormulario();
    this.criarFormularioLogin();
    this.getConfiguracaoClienteExigeCertificadoCNPJ();

    if (this.sessionService.getToken()) {
      this.router.navigate(['/home']);
    }
    if (window.localStorage.getItem('sentToSignIn') !== null) {
      localStorage.removeItem('sentToSignIn');
      location.reload();
    }

    this.aplicacoesService.obterAplicacaoComAcessoIrrestrito().subscribe(
      res => {
        this.loading = false;

        res['resultado'].forEach(element => {
          element.iconImage =
            !element.icone || element.icone === -1
              ? ''
              : this.icones[element.icone].name;
        });

        this.aplicacoes = res['resultado'];
      },
      error => {
        this.loading = false;
      }
    );
  }

  criarFormularioLogin() {
    this.loginForm = this.fb.group({
      cpfCnpj: [
        '',
        Validators.compose([Validators.required, Validators.minLength(14)])
      ],
      senha: ['', Validators.required]
    });
  }

  changeLoginStatus(value) {
    this.loginStatus = value;
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      searchText: ''
    });
  }

  format(valString) {
    if (!valString) {
      return '';
    }
    const val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    if (parts[0].length <= 11) {
      this.maskedId = this.cpf_mask(parts[0]);
      return this.maskedId;
    } else {
      this.maskedId = this.cnpj(parts[0]);
      return this.maskedId;
    }
  }

  onSubmit(submitBtn: HTMLButtonElement) {
    this.currentCnpj = this.loginForm.controls['cpfCnpj'].value;
    submitBtn.disabled = true;
    this.hasError = false;
    this.loading = true;
    const obj = this.loginForm.value;
    this.loginForm.reset();
    const unformatedCpfCnpj = this.unFormat(obj.cpfCnpj);

    // if(unformatedCpfCnpj.length == 14 && this.exigeCertificadoCnpj){
    //  this.alertaExigeCertificado();
    // }else{
      obj['cpfCnpj'] = unformatedCpfCnpj;
      this.userService.setLogOnData(obj['cpfCnpj'], obj['senha']);
      this.loginService
        .login(obj)
        .subscribe(
          res => this.handleResponse(res),
          error => this.handleError(error)
        );
  }

  alertaExigeCertificado() {

    if(this.exigeCertificadoCnpj) {

      swal.fire(
        getSwal(
          'warning',
          'Exige certificado Cnpj',
          'O acesso de empresa jurídica deverá ser realizado via certificado digital. Selecione a opção Acesso por Certificado para realizar o acesso.',
          'Fechar',
          null
        )
      ).then(res => {
        if (res.value) {
          window.location.reload();
        }
      });
    }
  }

  getConfiguracaoClienteExigeCertificadoCNPJ(){

    return new Promise<void>((resolve, reject) => {
      this.cadastroService.getConfiguracaoClienteExigeCertificadoCNPJ().subscribe(
        res => {
          this.exigeCertificadoCnpj = res.resultado;
          resolve();
        },
        error => {
          reject();
        },
      );
    });

  }

  handleResponse(res) {
    this.loading = false;
    const userInfo = res.resultado;
    if (this.currentCnpj.length >= 15) {
      // let companysAvailable = res.resultado.empresas;
      if (res.resultado.empresas !== null) {
        /*
         */
        this.userService.setCompanysAvailable(res.resultado.empresas);
        this.router.navigate(['/filiais']);
        this.userService.sendInfo({
          loggedIn: false,
          ...res['resultado'].empresas
        });
      } else {
        this.userService.sendInfo({ loggedIn: false, ...res.resultado });
        this.loginConfigs(res.resultado);
      }
    } else {
      this.userService.sendInfo({ loggedIn: false, ...res.resultado });
      this.loginConfigs(res.resultado);
    }
  }

  loginConfigs(obj) {
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      res => {
        let response = res.resultado;
        // let clients = [];

        // response.forEach(element => {
        //   if (clients.indexOf(element.cliente.codigo) === -1) {
        //     clients.push(element.cliente.codigo);
        //   }
        // });

        if (response.length <= 1) {
          response = res.resultado[0];
          this.loginService
            .obterSessao(response.codigo)
            .then(data => {
              if (data['resultado'].perfilAcesso.codigo !== 3) {
                this.userService.sendInfo({
                  loggedIn: true,
                  firstVisit: true,
                  ...data['resultado']
                });
                this.router.navigate(['/home']);
                window.location.reload();
              } else {
                this.userService.sendInfo({
                  loggedIn: false,
                  ...data['resultado']
                });
                this.router.navigate(['/procuracoes']);
              }
            })
            .catch(error => {
              this.handleError(error);
            });
        } else {
          this.router.navigate(['perfis']);
        }
      },
      error => this.handleError(error)
    );
  }

  handleError(err) {
    const error = err['error'];
    this.loading = false;
    this.hasError = true;
    this.errorMsg = error.excecao.recomendacao;

    swal.fire(
      getSwal(
        ['E000', 'E004'].indexOf(error.excecao.codigo) >= 0 ? 'warning' : 'error',
        ``,
        error.excecao.recomendacao,
        'ok',
        null
      )
    ).then(res => {
      this.loginForm.reset();
      this.userService.sendInfo({
        loggedIn: false
      });
      this.sessionService.clearSession();
      this.router.navigate(['index']);
    });
  }

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/\D/g, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  }

  cpf_mask(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return v;
  }

  cnpj(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
    return v;
  }

  setLabels() {
    this.labels = {
      descricao: this.translate.instant('INDEX.BEMVINDOTEXTO'),
      titulo: this.translate.instant('INDEX.BEMVINDOTITULO'),
      consultaspublicas: this.translate.instant('INDEX.CONSULTASPUBLICAS'),
      tituloacesso: this.translate.instant('INDEX.TITULOACESSO'),
      porsenha: this.translate.instant('INDEX.PORSENHA'),
      porcertificado: this.translate.instant('INDEX.PORCERTIFICADO'),
      cadastrese: this.translate.instant('INDEX.CADASTRESE'),
      entrar: this.translate.instant('INDEX.ENTRAR'),
      acesso: this.translate.instant('INDEX.ACESSO'),
      esqueceusenha: this.translate.instant('INDEX.ESQUECEUSENHA')
    };
  }
}
