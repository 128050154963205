import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/throw";

import { environment } from "../../../environments/environment";

@Injectable()
export class CaixaPostalService {
  private mensagens = new Subject<any>();
  mensagens$ = this.mensagens.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  getMessagesNumbers(): Observable<any> {
    const comResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensComRespostas`)
      .map(res => res);
    const enviadas = this.http
      .get(`${this.url}/mensagens/totalMensagensEnviadas`)
      .map(res => res);
    const semResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensSemRespostas`)
      .map(res => res);
    return Observable.forkJoin([comResposta, enviadas, semResposta]);
  }

  getMessagesNumbersToBadge(): Observable<any> {
    const comResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensComRespostas`)
      .map(res => res);
    const ciencia_tacita = this.http
      .get(`${this.url}/mensagens/totalMensagensCienciaTacita`)
      .map(res => res);
    const enviadas = this.http
      .get(`${this.url}/mensagens/totalMensagensEnviadas`)
      .map(res => res);
    const semResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensSemRespostas`)
      .map(res => res);
    const nao_lidas = this.http
      .get(`${this.url}/mensagens/totalMensagensEnviadas?lidas=false`)
      .map(res => res);

    const cienciaAVencer = this.http
      .get(`${this.url}/mensagens/totalMensagensCienciaTacitaAVencerContribuinte`)
      .map(res => res);

    const cienciaVencidas = this.http
      .get(`${this.url}/mensagens/totalMensagensCienciaTacitaVencidasContribuinte`)
      .map(res => res);

    const naoLidosNoPrazoDeLeitura = this.http
      .get(`${this.url}/mensagens/totalComunicadosNaoLidosDentroPrazoLeitura`)
      .map(res => res);

    const lidosDentroPrazoLeitura = this.http
      .get(`${this.url}/mensagens/totalComunicadosLidosDentroPrazoLeitura`)
      .map(res => res);

    return Observable.forkJoin([
      comResposta,
      ciencia_tacita,
      enviadas,
      semResposta,
      nao_lidas,
      cienciaAVencer,
      cienciaVencidas,
      naoLidosNoPrazoDeLeitura,
      lidosDentroPrazoLeitura
    ]);
  }

  getMensagens(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/mensagens`, { params: params })
      .map(data => {
        const body = data;
        this.mensagens.next(body);
        return body;
      });
  }
  
  getListaPaginacaoResposta(obj): Observable<any> {
    const params = this.setParams(obj);
    return this.http.get(`${this.url}/mensagens/listaPaginacao`, { params })
      .map(data => {
        const body = data;
        this.mensagens.next(body);
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  consultarMensagem(codigo: any): Observable<any> {
    const protocolo = codigo;
    return this.http.get(`${this.url}/mensagens/${protocolo}`).map(data => {
      return data;
    });
  }

  consultarPopUpMensagem(): Observable<any> {
    return this.http.get(`${this.url}/mensagens/popup`).map(data => {
      return data;
    });
  }

  consultarContribuinte(cpf_cnpj): Observable<any> {
    const params = new HttpParams().set("cpf_cnpj", cpf_cnpj);
    return this.http
      .get(`${this.url}/contribuintes`, { params: params })
      .map(data => {
        return data;
      });
  }

  mensagemLida(protocolo): Observable<any> {
    const params = new HttpParams().set("protocolo", protocolo);
    return this.http
      .get(`${this.url}/mensagens/marcarComoLida`, { params: params })
      .map(data => {
        return data;
      });
  }

  arquivarMensagem(protocolo: any): Observable<any> {
    const params = new HttpParams().set("protocolo", protocolo);
    return this.http
      .get(`${this.url}/mensagens/arquivar`, { params: params })
      .map(data => {
        return data;
      });
  }
  desarquivarMensagem(protocolo: any): Observable<any> {
    const params = new HttpParams().set("protocolo", protocolo);
    return this.http
      .get(`${this.url}/mensagens/desarquivar`, { params: params })
      .map(data => {
        return data;
      });
  }

  gerarDocumentoCiencia(protocolo, certificado): Observable<any> {
    const fd = new FormData();
    fd.append("certificado", certificado);
    return this.http
      .post(`${this.url}/mensagens/${protocolo}/gerarDocumentoCiencia`, fd, {
        headers: new HttpHeaders().set("Content-Type", "multipart/form-data")
      })
      .map(data => {
        return data;
      });
  }

  darCiencia(protocolo, documento): Observable<any> {
    return this.http
      .post(`${this.url}/mensagens/${protocolo}/darCiencia`, documento)
      .map(data => {
        let body = data;
        return body;
      });
  }

  gerarDossie(protocolo): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/${protocolo}/dossie`, {
        responseType: "arraybuffer"
      })
      .map(data => {
        return data;
      });
  }

  gerarProtocolo(protocolo): Observable<any> {
    return this.http.get(`${this.url}/mensagens/${protocolo}/protocolo`, {
      responseType: "arraybuffer"
    });
  }

  nomearAdvogado(protocolo, procuracoes): Observable<any> {
    return this.http
      .post(`${this.url}/mensagens/${protocolo}/nomearAdvogados`, procuracoes)
      .map(data => {
        let body = data;
        return body;
      });
  }

  responderMensagem(protocolo, mensagem, anexos): Observable<any> {
    const fd = new FormData();
    fd.append("protocolo", protocolo);
    fd.append("mensagem", mensagem);
    Array.from(Array(anexos.length).keys()).map(x => {
      fd.append("anexos", anexos[x], anexos[x].name);
    });
    return this.http
      .post(`${this.url}/mensagens/responder`, fd, {
        headers: new HttpHeaders().set("Content-Type", "multipart/form-data,charset=UTF-8")
        .set("Content-Transfer-Encoding", "charset=UTF-8")
      })
      .map(data => {
        return data;
      });
  }

  baixarAnexo(protocolo, codigoAnexo) {
    return this.http
      .get(`${this.url}/mensagens/${protocolo}/anexos/${codigoAnexo}`, {
        responseType: "arraybuffer"
      })
      .map(data => {
        return data;
      });
  }

  baixarTodosAnexos(protocolo) {
    return this.http
      .get(`${this.url}/mensagens/${protocolo}/anexos`, {
        headers: new HttpHeaders().set("Content-Type", "application/zip"),
        responseType: "arraybuffer"
      })
      .map(data => {
        return data;
      });
  }

  enviarMensagem(mensagem) {
    return this.http
      .post(`${this.url}/mensagens/enviar`, mensagem)
      .map(data => {
        return data;
      });
  }

  obterAplicacoesPorTipoComunicado(id: number): Observable<any> {
    return this.http.get(`${this.url}/mensagens/listaAplicacoesPorTipoComunicado/${id}`).map(data => {
      const body = data;
      console.log(data);
      return body;
    });
  }
}
